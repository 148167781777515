import { defineStore } from "pinia";

export interface Observer {
  start: () => void;
  stop: () => void;
}

// ブラウザタブ状態に応じて監視を制御するストア
// - add: 監視対象を追加する
// - remove: 監視対象を削除する
// - 監視対象は Observer インターフェースを実装したオブジェクト
//   - Observer.start: 監視を開始する。冪等性が必須
//   - Observer.stop: 監視を停止する。冪等性が必須
export const useObserverStore = defineStore("ObserverStore", () => {
  // 監視対象を保持
  const observers: Record<string, Observer> = {};
  // 監視中かどうか
  let isActive = true;

  // ブラウザタブを監視。各種イベントを駆使して監視状態を切り替える

  // ブラウザタブ監視有効・無効フラグ
  const ENABLE_TAB_OBSERVATION = true;

  // https://developer.mozilla.org/ja/docs/Web/API/Document/visibilitychange_event
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      startObservers("visibilitychange");
    } else if (document.visibilityState === "hidden") {
      stopObservers("visibilitychange");
    }
  });
  // https://developer.mozilla.org/ja/docs/Web/API/Window/pageshow_event
  window.addEventListener("pageshow", () => startObservers("pageshow"));
  // https://developer.mozilla.org/ja/docs/Web/API/Window/pagehide_event
  window.addEventListener("pagehide", () => stopObservers("pagehide"));
  // https://developer.mozilla.org/ja/docs/Web/API/Window/pageshow_event
  window.addEventListener("focus", () => startObservers("focus"));
  // https://developer.mozilla.org/ja/docs/Web/API/Window/blur_event
  window.addEventListener("blur", () => stopObservers("blur"));

  // イベントで拾いきれない場合を考慮して、定期的に監視状態を切り替える
  setInterval(() => {
    if (document.visibilityState === "visible") {
      startObservers("setInterval");
    } else if (document.visibilityState === "hidden") {
      stopObservers("setInterval");
    }
  }, 3000);

  // 監視を開始
  const startObservers = (_key: string) => {
    if (!ENABLE_TAB_OBSERVATION || isActive) {
      return;
    }
    Object.values(observers).forEach(observer => {
      observer.start();
    });
    isActive = true;
  };

  // 監視を停止
  const stopObservers = (_key: string) => {
    if (!ENABLE_TAB_OBSERVATION || !isActive) {
      return;
    }
    Object.values(observers).forEach(observer => {
      observer.stop();
    });
    isActive = false;
  };

  // 監視対象を追加する
  const add = (key: string, observer: Observer) => {
    const oldObserver = observers[key];
    if (oldObserver) {
      oldObserver.stop();
    }
    observer.start();
    observers[key] = observer;
  };

  // 監視対象を削除する
  const remove = (key: string) => {
    const observer = observers[key];
    if (!observer) {
      return;
    }
    observer.stop();
    delete observers[key];
  };

  return {
    add,
    remove,
  };
});
