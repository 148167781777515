import { NotificationSettingItem, NotificationSettings } from "~/entities/NotificationSetting";
import type { INotificationSettingRepository } from "~/interfaces/INotificationSettingRepository";

export class NotificationSettingRepository implements INotificationSettingRepository {
  async getSettings(planId: string): Promise<NotificationSettings> {
    const res = await GetRequest<{
      statusCode: number;
      webPushSettings: any;
      lineSettings: any;
      pushQuaySettings: any;
    }>(`/notification_setting/${planId}`, false, true);
    return new NotificationSettings(res.webPushSettings || [], res.lineSettings || [], res.pushQuaySettings || []);
  }

  async putSettings(planId: string, settings: NotificationSettings): Promise<void> {
    const convertSetting = (setting: NotificationSettingItem) => {
      const convertNotificationType = (notificationType: string) => {
        if (notificationType === "webpush") {
          return "web_push_notification";
        } else if (notificationType === "line") {
          return "line_notification";
        } else if (notificationType === "pushquay") {
          return "pushquay_notification";
        } else {
          throw new Error("Invalid notificationType");
        }
      };
      if (setting.contentsType === "talk_room") {
        return {
          notification_type: convertNotificationType(setting.notificationType),
          contents_type: setting.contentsType,
          contents_sub_type: setting.contentsSubType,
          talk_room_id: setting.roomId,
        };
      } else {
        return {
          notification_type: convertNotificationType(setting.notificationType),
          contents_type: setting.contentsType,
          contents_sub_type: setting.contentsSubType,
        };
      }
    };
    const webPushSettingsToPut = settings.webpushSettings.map(setting => convertSetting(setting));
    const lineSettingsToPut = settings.lineSettings.map(setting => convertSetting(setting));
    const pushQuaySettingsToPut = settings.pushQuaySettings.map(setting => convertSetting(setting));
    const settingsToPut = {
      settings: webPushSettingsToPut.concat(lineSettingsToPut).concat(pushQuaySettingsToPut),
    };
    await PutRequest<{ statusCode: number }>(
      `/notification_setting/${planId}`,
      JSON.stringify(settingsToPut),
      true,
      true,
    );
  }
}
