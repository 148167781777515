<script setup lang="ts">
  import { useDistributionStore } from "~/stores/DistributionStore";
  import { useHistoryStore } from "~/stores/HistoryStore";

  interface Props {
    planId?: string;
    isOpen?: boolean;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  const props = defineProps<Props>();

  interface Emits {
    (event: "update:isOpen", isOpen: boolean): void;
  }
  const emit = defineEmits<Emits>();

  const historyStore = useHistoryStore();

  const computedIsOpen = computed<boolean>({
    get: () => props.isOpen,
    set: value => {
      emit("update:isOpen", value);
    },
  });

  const distributionStore = useDistributionStore();
  const onSelectDistribution = () => {
    const type = selections.value[selected.value!].type;
    // distributionStore をリセットしてから配信作成画面へ遷移
    distributionStore.reset();
    historyStore.to(`/plan/${props.planId}/distribute/${type}/create`);
  };

  const selected = ref<number | undefined>(undefined);
  const selections = computed(() => {
    const items = [
      {
        type: "live",
        icon: "assets/images/icon/distribution/live.svg",
        text: "ライブ配信をする",
      },
      {
        type: "movie",
        icon: "assets/images/icon/distribution/movie.svg",
        text: "動画を投稿する",
      },
      {
        type: "document",
        icon: "assets/images/icon/distribution/document.svg",
        text: "資料を投稿する",
      },
    ];
    if (featureArticle()) {
      items.push({
        type: "article",
        icon: "assets/images/icon/distribution/article.svg",
        text: "記事を投稿する",
      });
    }
    return items;
  });

  // レスポンシブ対応
  const isSp = isSpDisplayRef();
</script>

<template>
  <v-dialog v-model="computedIsOpen" :width="isSp ? '90%' : '70%'" max-height="90%" scrim="black" :attach="!!isTest">
    <div
      class="d-flex flex-column justify-center dialog-body"
      :class="[isSp ? 'px-4 py-4' : 'px-16 py-8']"
      data-testid="distribution-selection-dialog"
    >
      <div class="close-button" @click="computedIsOpen = false">✕</div>
      <div :class="[isSp ? 'heading-sp' : 'heading']">配信内容を選択してください</div>

      <div
        v-for="(item, i) in selections"
        :key="i"
        class="d-flex align-center"
        :class="{ 'item-active': i === selected, 'item-sp': isSp, item: !isSp }"
        @click="selected = i"
      >
        <AtomsSvgIcon class="item-icon" :width="32" :img-path="item.icon"></AtomsSvgIcon>
        <div class="item-text">{{ item.text }}</div>
      </div>

      <div class="d-flex justify-center mt-2 mb-4">
        <AtomsDialogButton class="mt-4" color="monochrome-02" @click="computedIsOpen = false"
          >キャンセル</AtomsDialogButton
        >
        <AtomsDialogButton class="mt-4" color="main-04" :disabled="selected === undefined" @click="onSelectDistribution"
          >決定</AtomsDialogButton
        >
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog-body {
    height: 100%;
    color: white;
    background-color: #222222;
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: center;
    margin: 16px 0;
  }
  .heading-sp {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: center;
    margin: 8px 0 16px 0;
  }
  .item {
    background-color: #333333;
    cursor: pointer;
    margin: 8px 0;
    padding: 20px 24px;
  }
  .item-sp {
    background-color: #333333;
    cursor: pointer;
    margin: 4px 0;
    padding: 12px;
  }
  .item-active {
    background-color: rgb(var(--v-theme-primary));
  }
  .item-icon {
    margin-right: 32px;
  }
  .item-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    text-align: left;
    color: rgb(var(--v-theme-monochrome-01));
  }
</style>
