<script setup lang="ts">
  interface Props {
    isOpen?: boolean;
    hasUnread?: boolean;
  }
  const props = defineProps<Props>();

  interface Emits {
    (event: "update:isOpen", isOpen: boolean): void;
  }
  const emit = defineEmits<Emits>();

  const panelValue = "p";
  const panel = ref(props.isOpen ? panelValue : "");
  watchEffect(() => {
    emit("update:isOpen", !!panel.value);
  });

  const isSp = isTabletAndSpDisplayRef();
</script>

<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel :value="panelValue">
      <v-expansion-panel-title :hide-actions="true" class="title px-2 py-0 justify-start" :class="{ 'title-sp': isSp }">
        <v-icon v-if="!!panel" icon="$mdiMenuDown" size="large" />
        <v-icon v-else icon="$mdiMenuRight" size="large" />
        <slot name="title"></slot>
        <v-badge v-if="hasUnread" color="red" dot class="badge" :class="{ 'badge-sp': isSp }" :offset-y="-3" />
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <slot></slot>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style lang="scss" scoped>
  .title {
    position: relative;
    color: #a2a2a2 !important;
    font-weight: 700;
    height: 32px;
  }
  .title-sp {
    border-bottom: 1px solid rgba(57, 57, 57, 1);
  }
  .badge {
    position: absolute;
    right: 6px;
    // 中央揃え
    top: 50%;
  }
  .badge-sp {
    right: 12px;
  }
</style>

<style lang="scss">
  .v-expansion-panel {
    background-color: transparent;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
    min-height: 32px;
  }
  .v-expansion-panel-title {
    min-height: 32px;
    padding-right: 0 !important;
  }
  .v-expansion-panel-title__overlay {
    background-color: transparent;
  }
  .v-expansion-panel__shadow {
    box-shadow: none;
  }
  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
</style>
