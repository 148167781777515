//  'userAgentData' は型 'Navigator' に存在していません。'userAgent' ですか?ts(2551) という警告の対策
declare global {
  interface Navigator {
    userAgentData?: {
      getHighEntropyValues: (hints: string[]) => Promise<{ platform: string }>;
    };
  }
}

export const isIOS = () => {
  const platform = detectPlatformByUserAgent();
  return platform === "ios";
};

export const isSafari = () => {
  // iOS の場合、ブラウザは必ず webkit ベースなので、 safari とみなす
  // ref: https://qiita.com/pink/items/a54b8cadbe39a06956a9
  if (isIOS()) {
    return true;
  }

  // その他の OS の場合
  // ref: https://www.whatismybrowser.com/guides/the-latest-user-agent/
  return (
    /Safari/.test(navigator.userAgent) &&
    // PC Chrome および Edge も Safari を含むが、 Chrome も併せ持つのでそれを除外判定
    !/Chrome/.test(navigator.userAgent) &&
    // SP Chrome および Edge も Safari を含むが、 CriOS も併せ持つのでそれを除外判定
    !/CriOS/.test(navigator.userAgent)
  );
};

export const isAndroid = () => {
  const platform = detectPlatformByUserAgent();
  return platform === "android";
};

// プラットフォームを判定する
// return: windows, mac, ios, android, other
export const detectPlatform = async (): Promise<"windows" | "mac" | "ios" | "android" | "other"> => {
  // userAgentData が使える場合はそれを使う。safariなどはまだ対応していない
  // https://developer.mozilla.org/ja/docs/Web/API/Navigator/userAgentData
  if (navigator.userAgentData) {
    const data = await navigator.userAgentData.getHighEntropyValues(["platform"]);
    const platform = data.platform.toLowerCase();
    if (platform.includes("ios")) return "ios";
    if (platform.includes("android")) return "android";
    if (platform.includes("windows")) return "windows";
    if (platform.includes("mac")) return "mac";
    return "other";
  } else {
    return detectPlatformByUserAgent();
  }
};

// プラットフォームを判定する。detectPlatformはasyncなので、computedなどで使う場合はこちらを使う
// return: windows, mac, ios, android, other
export const detectPlatformByUserAgent = (): "windows" | "mac" | "ios" | "android" | "other" => {
  const userAgent = navigator.userAgent.toLocaleLowerCase();
  // iphoneのuserAgentにはMac OS Xが含まれるので、先に判定する
  // 例: Mozilla/5.0 (iPhone; CPU iPhone OS 18_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.2 Mobile/15E148 Safari/604.1
  if (/iphone|ipad|ipod/.test(userAgent)) return "ios";
  // iPadOS13以上はipadを含まずmacintoshを含むため、ipadの判定は先に行う
  if (/macintosh/.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) return "ios";
  if (/android/.test(userAgent)) return "android";
  if (/windows/.test(userAgent)) return "windows";
  if (/macintosh|mac os x/.test(userAgent)) return "mac";
  return "other";
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    isIOS: () => boolean;
    isSafari: () => boolean;
    isAndroid: () => boolean;
  }
}
