import { defineStore } from "pinia";
import { useContentsStore } from "./ContentsStore";
import { LiveInfo } from "~/entities/LiveInfo";
import { ContentList } from "~/entities/ContentList";
import { LiveResult } from "~/entities/LiveResult";
import { VideoStreamingTime } from "~/entities/VideoStreamingTime";
import type { ILiveRepository } from "~/interfaces/ILiveRepository";
import { LiveRepository } from "~/Repositories/LiveRepository";

export const useLiveStore = defineStore("LiveStore", () => {
  const repository: ILiveRepository = new LiveRepository();

  const contentStore = useContentsStore();

  const live = ref<LiveInfo>();
  const lives = ref<ContentList<LiveInfo>>();
  const streamings = ref<ContentList<LiveInfo>>(); // ライブ、スケジュール、アーカイブ混在データ。
  const liveResult = ref<LiveResult>();
  const loading = ref(false);
  const loadingList = ref(false);

  /**
   *
   * @param contentsId
   * @param withoutLoadingAndReset true: loadingの変更とliveのリセットをしない。データの更新などに使う
   */
  const fetchOne = async (contentsId: string, withoutLoadingAndReset = false) => {
    if (!withoutLoadingAndReset) {
      loading.value = true;
      live.value = undefined;
    }
    try {
      live.value = await contentStore.getDetail<LiveInfo>(contentsId);
    } finally {
      if (!withoutLoadingAndReset) {
        loading.value = false;
      }
    }
  };

  const fetch = async (
    page: number,
    pageSize: number,
    planId: string | undefined = undefined,
    options?: Record<string, any>,
  ) => {
    loadingList.value = true;
    lives.value = undefined;
    try {
      lives.value = await contentStore.getList<ContentList<LiveInfo>>(page, pageSize, {
        contents_types: ["live_movie"],
        plan_id: planId,
        ...options,
      });
    } finally {
      loadingList.value = false;
    }
  };

  /**
   * 指定のライブ配信情報を取得する。ライブ、スケジュール、アーカイブ混在データ。
   */
  const fetchStreamings = async (
    page: number,
    pageSize: number,
    isFree: boolean | undefined,
    planId: string | undefined = undefined,
    options?: Record<string, any>,
  ) => {
    loadingList.value = true;
    streamings.value = undefined;
    try {
      streamings.value = await contentStore.getList<ContentList<LiveInfo>>(page, pageSize, {
        contents_types: ["live_movie", "archive_movie", "schedule"],
        plan_id: planId,
        is_free: isFree,
        ...options,
      });
    } finally {
      loadingList.value = false;
    }
  };

  /**
   * コンテンツ閲覧画面下部に表示するデータを取得する
   */
  const fetchPlanStreamings = async (
    page: number,
    pageSize: number,
    planId: string | undefined = undefined,
    exclusionContentsId: string | undefined = undefined,
  ) => {
    loadingList.value = true;
    try {
      const res = await contentStore.getList<ContentList<LiveInfo>>(page, pageSize, {
        contents_types: ["live_movie", "archive_movie", "schedule"],
        plan_id: planId,
        exclusion_contents_id: exclusionContentsId,
      });
      return res.contents;
    } finally {
      loadingList.value = false;
    }
  };

  /**
   * 配信準備
   */
  const streamingPrepare = async (liveId: string): Promise<void> => {
    loading.value = true;
    try {
      const response = await repository.streamingPrepare(liveId);
      if (live.value && response?.agoraInfo) {
        live.value.agora = response.agoraInfo;
      }
    } finally {
      loading.value = false;
    }
  };
  /**
   * 配信開始
   */
  const streamingStart = async (liveId: string, videoStreaming: boolean): Promise<void> => {
    loading.value = true;
    try {
      await repository.streamingStart(liveId, videoStreaming);
    } finally {
      loading.value = false;
    }
  };
  /**
   * 配信終了
   */
  const streamingEnd = async (liveId: string): Promise<void> => {
    loading.value = true;
    try {
      await repository.streamingEnd(liveId);
    } finally {
      loading.value = false;
    }
  };

  /**
   * ライブ終了時刻をセットする。
   * 視聴時にfirestoreからの終了通知を受け取ったあと終了時刻をセットし、終了判定をさせるためのメソッド
   */
  const setLiveEndDateTime = () => {
    if (live.value) {
      if (!live.value?.liveEndDateTime) {
        live.value.liveEndDateTime = new Date().toISOString();
      }
    }
  };

  /**
   * ビデオミュート切り替えフラグの送信
   */
  const postVideoStreamingTimes = async (liveId: string, videoStreaming: boolean): Promise<boolean> => {
    const value = new VideoStreamingTime(videoStreaming, new Date().toISOString());
    const response = await repository.postVideoStreamingTimes(liveId, [value]);
    return response.statusCode === 200;
  };

  return {
    live,
    lives,
    streamings,
    liveResult,
    loading,
    loadingList,
    fetchOne,
    fetch,
    fetchStreamings,
    fetchPlanStreamings,
    streamingPrepare,
    streamingStart,
    streamingEnd,
    setLiveEndDateTime,
    postVideoStreamingTimes,
  };
});
