import { defineStore } from "pinia";
import { useLoginStore } from "./LoginStore";
import { PushQuayRepository } from "~/Repositories/PushQuayRepository";

export const usePushQuayStore = defineStore("PushQuayStore", () => {
  const repository = new PushQuayRepository();
  const loginStore = useLoginStore();

  const loading = ref(false);

  const isPushQuaySupported = () => {
    return isIOS() || isAndroid();
  };

  const registerPushQuayToken = async (token: string, force = false) => {
    if (loading.value) return;
    loading.value = true;
    try {
      // force が true の場合は強制的に登録する
      // デバイストークンが変更されていたら or 以前登録してから24時間経っていたら登録する
      if (force || loginStore.shouldSendPushQuayToken(token)) {
        loginStore.setPushQuayToken(token);
        await repository.registerPushQuayToken(token);
      }
    } finally {
      loading.value = false;
    }
  };

  const registerPushQuayNotification = async (planId: string) => {
    loading.value = true;
    try {
      await repository.registerPushQuayNotification(planId);
    } finally {
      loading.value = false;
    }
  };

  const unregisterPushQuayNotification = async (planId: string) => {
    loading.value = true;
    try {
      await repository.unregisterPushQuayNotification(planId);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    isPushQuaySupported,
    registerPushQuayToken,
    registerPushQuayNotification,
    unregisterPushQuayNotification,
  };
});
