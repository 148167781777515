import { useDisplay } from "vuetify";
import { useScreenOrientation } from "@vueuse/core";
import DOMPurify from "dompurify";

// メインヘッダーの高さ
const HEADER_HEIGHT = 80;
const HEADER_HEIGHT_LANDSCAPE = 0;

export const getHeaderHeightRef = (enableLandscapeHeader: boolean = false): Ref<number> => {
  const isSpLandscape = isSpLandscapeRef();

  if (enableLandscapeHeader) {
    return computed<number>(() => {
      if (isSpLandscape.value) {
        return HEADER_HEIGHT_LANDSCAPE;
      }
      return HEADER_HEIGHT;
    });
  }
  return computed<number>(() => {
    return HEADER_HEIGHT;
  });
};

// スマホの横向きかどうか
export const isSpLandscapeRef = (): Ref<boolean> => {
  const { platform, height, thresholds } = useDisplay();
  const { isSupported, orientation } = useScreenOrientation();
  return computed<boolean>(() => {
    // platform.value.iosだけではipadも含まれるため、height.valueで判定
    if (isSupported.value && (platform.value.android || platform.value.ios) && height.value < thresholds.value.sm) {
      if (orientation.value === "landscape-primary" || orientation.value === "landscape-secondary") {
        return true;
      }
    }
    return false;
  });
};

// スマホかどうか
export const isSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs");
};

// タブレット以下かどうか
export const isTabletAndSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs" || name.value === "sm");
};

// タブレット以下かつ横向きでないかどうか
export const isTabletAndSpWithoutLandscapeDisplayRef = (): Ref<boolean> => {
  const isSpLandscape = isSpLandscapeRef();
  const isTabletAndSpDisplay = isTabletAndSpDisplayRef();
  return computed<boolean>(() => {
    if (isSpLandscape.value) {
      return false;
    }
    return isTabletAndSpDisplay.value;
  });
};

// コンテンツカードの横幅
export const getContentCardWidth = (): Ref<number> => {
  const isSpDisplay = isSpDisplayRef();
  return computed<number>(() => {
    if (isSpDisplay.value) {
      return Math.min(330, window.screen.width - 48);
    }
    return Math.min(220, window.screen.width - 48);
  });
};

// オーナーカードの横幅
export const getOwnerCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

// 講座カードの横幅
export const getPlanCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

export const requestFullScreen = (element: any) => {
  if (element.requestFullScreen) {
    element.requestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.webkitEnterFullscreen) {
    element.webkitEnterFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const exitFullScreen = (element: any) => {
  if (element.exitFullscreen) {
    element.exitFullscreen();
  } else if (element.cancelFullScreen) {
    element.cancelFullScreen();
  } else if (element.mozCancelFullScreen) {
    element.mozCancelFullScreen();
  } else if (element.webkitCancelFullScreen) {
    element.webkitCancelFullScreen();
  } else if (element.msExitFullscreen) {
    element.msExitFullscreen();
  }
};

export const isPWA = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  return false;
};

export const checkAppInBrowser = (): string | null => {
  const ua = navigator.userAgent.toLowerCase().trim();

  // Facebook
  if (ua.includes("fbios") || ua.includes("fb_iab")) {
    return isIOS() ? "is_facebook_ios" : isAndroid() ? "is_facebook_android" : "is_facebook_unknown";
  }

  // Instagram
  if (ua.includes("instagram")) {
    return isIOS() ? "is_instagram_ios" : isAndroid() ? "is_instagram_android" : "is_instagram_unknown";
  }

  // LINE
  if (ua.includes(" line/")) {
    return isIOS() ? "is_line_ios" : isAndroid() ? "is_line_android" : "is_line_unknown";
  }

  // iOS Twitter Or X
  if (isIOS() && /safari\/[0-9.]+$/.test(ua)) {
    if (document.referrer.includes("t.co")) {
      return "is_x_ios";
    }
  }

  // Android Twitter Or X
  if (isAndroid() && /safari\/[0-9.]+$/.test(ua)) {
    if (document.referrer.includes("t.co")) {
      return "is_x_android";
    }
  }
  return null;
};

export const copyText = (value: string): boolean => {
  if (!navigator.clipboard) {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    textarea.style.fontSize = "20px";
    document.body.appendChild(textarea);
    if (isIOS()) {
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textarea.setSelectionRange(0, 999999);
    } else {
      textarea.select();
    }
    document.execCommand("copy");
    document.body.removeChild(textarea);
    return true;
  } else {
    navigator.clipboard.writeText(value);
    return true;
  }
};

export const omitString = (value: string | undefined | null, length: number): string => {
  if (!value) {
    return "";
  }
  if (value.length <= length) {
    return value;
  }
  return value.slice(0, length) + "...";
};

export const sanitizeDom = (html: string, length = Number.POSITIVE_INFINITY) => {
  if (length !== Number.POSITIVE_INFINITY) {
    html = omitString(html, length);
  }
  return DOMPurify.sanitize(html, { ADD_ATTR: ["target"] });
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    getHeaderHeightRef: (enableLandscapeHeader?: boolean) => Ref<number>;
    isSpLandscapeRef: () => Ref<boolean>;
    isSpDisplayRef: () => Ref<boolean>;
    isTabletAndSpDisplayRef: () => Ref<boolean>;
    isTabletAndSpWithoutLandscapeDisplayRef: () => Ref<boolean>;
    getContentCardWidth: () => Ref<number>;
    getOwnerCardWidth: () => Ref<number>;
    getPlanCardWidth: () => Ref<number>;
    requestFullScreen: (element: any) => void;
    exitFullScreen: (element: any) => void;
    isPWA: () => boolean;
    checkAppInBrowser: () => string | null;
    copyText: (value: string) => boolean;
    omitString: (value: string | undefined | null, length: number) => string;
    sanitizeDom: (html: string, length?: number) => string;
  }
}
