import { defineStore } from "pinia";
import { useLoginStore } from "./LoginStore";
import type { ILogRepository } from "~/interfaces/ILogRepository";
import { LogRepository } from "~/Repositories/LogRepository";

/**
 * ログを扱うクラス
 */
export const useLogStore = defineStore(
  "LogStore",
  () => {
    const repository: ILogRepository = new LogRepository();

    const loginStore = useLoginStore();
    const sentData = ref<{ user_id: string | null; device_id: string | null; platform: string | null }>({
      user_id: null,
      device_id: null,
      platform: null,
    });

    const sendPwaUsageLog = async () => {
      const userId = loginStore.loginInfo?.userId;
      const deviceToken = loginStore.deviceToken;
      const platform = await detectPlatform();

      if (
        sentData.value.user_id === userId &&
        sentData.value.device_id === deviceToken &&
        sentData.value.platform === platform
      ) {
        // 前回と同じ内容であれば送信しない
        return;
      }
      const data = {
        log_type: "use_webapp",
        user_id: userId,
        device_id: deviceToken,
        platform: platform,
      };
      try {
        await repository.sendPwaUsageLog(data);
        sentData.value = {
          user_id: userId,
          device_id: deviceToken,
          platform: platform,
        };
      } catch (error: any) {
        sentryErrorLog(`use_webapp log error: ${error}`);
      }
    };

    return {
      sendPwaUsageLog,
      sentData,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
