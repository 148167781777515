<script setup lang="ts"></script>

<template>
  <div class="line-btn-wrapper" data-testid="line-notification-btn">
    <v-btn size="small" class="line-btn line-btn-disactive text-center rounded px-2" :disabled="true">
      <img src="@/assets/images/icon/line.svg" class="mr-1" :width="24" alt="line" />
      <span class="text-white">講座の通知を受け取る</span>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
  .line-btn {
    width: 280px;
    height: 56px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    &.line-btn-disactive {
      background-color: #aaaaaa;
    }
  }
</style>
