import camelcaseKeys from "camelcase-keys";
import { jwtDecode } from "jwt-decode";
import { useLoginStore } from "~/stores/LoginStore";
import { useAuthStore } from "~/stores/AuthStore";
import { useErrorStore } from "~/stores/ErrorStore";

// runtime configでbase urlを設定
const getApiBaseURL = () => useRuntimeConfig().public.apiBaseUrl as string;

export type APIRequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export const GetRequest = <T>(
  url: string,
  noErrorDialog?: boolean, // 共通エラー処理で
  throwError?: boolean, // エラー時にthrowするかどうか
) => {
  return request<T>("GET", url, undefined, noErrorDialog, false, throwError);
};

export const PostRequest = <T>(
  url: string,
  body?: string | FormData | undefined,
  noErrorDialog?: boolean, // 共通エラー処理のダイアログを表示しない
  isRefreshed?: boolean, // トークンリフレッシュ処理かどうか
  throwError?: boolean, // エラー時にthrowするかどうか
) => {
  return request<T>("POST", url, body, noErrorDialog, isRefreshed, throwError);
};

export const PutRequest = <T>(
  url: string,
  body: string | FormData,
  noErrorDialog?: boolean, // 共通エラー処理のダイアログを表示しない
  throwError?: boolean, // エラー時にthrowするかどうか
) => {
  return request<T>("PUT", url, body, noErrorDialog, false, throwError);
};

export const PatchRequest = <T>(
  url: string,
  body: string | FormData,
  noErrorDialog?: boolean, // 共通エラー処理のダイアログを表示しない
  throwError?: boolean, // エラー時にthrowするかどうか
) => {
  return request<T>("PATCH", url, body, noErrorDialog, false, throwError);
};

export const DeleteRequest = <T>(
  url: string,
  noErrorDialog?: boolean, // 共通エラー処理のダイアログを表示しない
  throwError?: boolean, // エラー時にthrowするかどうか
) => {
  return request<T>("DELETE", url, undefined, noErrorDialog, false, throwError);
};

interface JwtPayload {
  sub: string;
  iat: number;
  exp: number;
}

const isSessionTokenValid = (sessionToken: string | undefined | null) => {
  if (!sessionToken) {
    return false;
  }
  try {
    jwtDecode(sessionToken, { header: true });
    return true;
  } catch {
    return false;
  }
};

const isSessionTokenExpired = (sessionToken: string) => {
  const decodedToken = jwtDecode(sessionToken) as JwtPayload;
  // console.log(decodedToken.exp * 1000 - Date.now());
  return decodedToken.exp * 1000 < Date.now();
};

const signOutThenGoHome = async () => {
  alert("認証エラーです。再度ログインしてください。");
  const authStore = useAuthStore();
  await authStore.signOut();

  // GTMのuser_idをリセットするためリロードしながらホームに戻る
  reloadNuxtApp({
    path: "/",
    ttl: 1000, // デフォルト 10000
  });
};

const request = async <T>(
  method: APIRequestMethod,
  url: string,
  body?: string | FormData | undefined,
  noErrorDialog?: boolean, // 共通エラー処理のダイアログを表示しない
  isRefreshed?: boolean, // トークンリフレッシュ処理かどうか
  throwError?: boolean, // エラー時にthrowするかどうか
): Promise<T> => {
  // 共通ヘッダー
  const headers: { [name: string]: string } = {};
  if (!(body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const loginStore = useLoginStore();
  let sessionToken;
  if (
    loginStore.isLogin() ||
    url === "/user/update_email" ||
    url === "/user/update_email_auth" ||
    url === "/auth_email"
  ) {
    // ログイン中、もしくはメールアドレス更新APIはログインしていなくても実行可能
    sessionToken = loginStore.getSessionToken();
  }
  if (sessionToken) {
    if (!isSessionTokenValid(sessionToken)) {
      // トークン不正であればログアウトしてトップに戻る
      await signOutThenGoHome();
      return {} as T;
    } else if (!isRefreshed && isSessionTokenExpired(sessionToken!)) {
      // トークン期限切れであればリフレッシュして再実行
      await loginStore.refresh();
      return request<T>(method, url, body, noErrorDialog, true, throwError);
    }
    headers.Authorization = `Bearer ${sessionToken}`;
  }

  let statusCode = 0;
  let data: any;

  try {
    data = await $fetch(getApiBaseURL() + url, {
      method: method,
      headers: headers,
      body: body,
      retry: false,
      credentials: "include", // CFn 署名付き Cookie 保存のため
      onResponse({ response }) {
        statusCode = response.status;
      },
    });
    // 応答データのsnake_caseをcamelCaseに変更する
    data = camelcaseKeys(data, { deep: true });
  } catch (error: any) {
    // 応答データのsnake_caseをcamelCaseに変更する
    let errorData: any = error.data;
    if (errorData) {
      errorData = camelcaseKeys(errorData, { deep: true });
    }
    if (throwError) {
      throw error;
    }
    if (error.status === 401) {
      // メール認証時の401エラーは無視する
      if (url === "/user/update_email_auth") {
        return errorData as T;
      }
      if (!isRefreshed) {
        // リフレッシュ直後のリクエストでない場合はリフレッシュトークン再取得してAPI再実行
        await loginStore.refresh();
        return request<T>(method, url, body, noErrorDialog, true);
      }

      // 再実行エラー・リフレッシュトークン取得エラーの場合はログアウトしてトップに戻る
      await signOutThenGoHome();
      return errorData as T;
    }

    const errorStore = useErrorStore();
    // 共通エラー処理のダイアログを表示しないかチェック
    if (!noErrorDialog) {
      errorStore.showConnectionError(`API: ${error.status} ${method} ${url} ${error.message}`);
    }
    return errorData as T;
  }
  if (data) {
    // data.valueがstatusCodeを持っていなかったら追加
    if (data.statusCode === undefined) {
      data = { ...data, statusCode };
    }
  }

  const res = data as any;

  // resにstatusCodeが含まれていない場合は追加
  if (!res.statusCode) {
    res.statusCode = statusCode;
  }

  return res as T;
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    GetRequest: <T>(url: string, noErrorDialog?: boolean, throwError?: boolean) => Promise<T>;
    PostRequest: <T>(
      url: string,
      body?: string | FormData | undefined,
      noErrorDialog?: boolean,
      isRefreshed?: boolean,
      throwError?: boolean,
    ) => Promise<T>;
    PutRequest: <T>(url: string, body: string | FormData, noErrorDialog?: boolean, throwError?: boolean) => Promise<T>;
    PatchRequest: <T>(
      url: string,
      body: string | FormData,
      noErrorDialog?: boolean,
      throwError?: boolean,
    ) => Promise<T>;
    DeleteRequest: <T>(url: string, noErrorDialog?: boolean, throwError?: boolean) => Promise<T>;
  }
}
