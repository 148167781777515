// トークチャンネル最大個数
export const TALK_ROOM_GROUP_MAX = 50;

// トークDM最大個数
// https://fincs.backlog.com/view/FINCS-3305
export const TALK_ROOM_DM_MAX = 700;

// トークチャンネル+DM+アーカイブ最大個数
// https://fincs.backlog.com/view/FINCS-3229
export const TALK_ROOM_TOTAL_MAX = 3000;

// トークアーカイブ最大個数
export const TALK_ROOM_ARCHIVE_MAX = TALK_ROOM_TOTAL_MAX - TALK_ROOM_GROUP_MAX - TALK_ROOM_DM_MAX;

// トーク入力最大文字数
export const TALK_INPUT_TEXT_MAX = 5000;

// トーク返信最大文字数
export const TALK_REPLY_TEXT_MAX = 270;

// トークユーザーデータクエリ最大数
export const TALK_USER_DATA_QUERY_MAX = 10;

// トークユーザーデータ項目テキスト最大文字数
export const TALK_USER_DATA_ITEM_TEXT_MAX = 50;

// トークブックマークのルームごと最大個数
export const TALK_BOOKMARK_PER_ROOM_MAX = 30;

// トークメンション用プレースホルダー
export const TALK_MENTION_PLACEHOLDER = "<mention>";

// トークメンション候補最大数
export const TALK_MENTION_USERS_MAX = 20;
