import { defineStore } from "pinia";
import { type RouteLocationRaw } from "vue-router";
import { type NavigateToOptions } from "@/node_modules/nuxt/dist/app/composables/router";

// 外部サイトを経由するとhistoryStore.back()が使えないので、独自のhistoryで管理する
export const useHistoryStore = defineStore(
  "HistoryStore",
  () => {
    const historyStack = ref<string[]>([]);
    const replaced = ref(false); // defineNuxtRouteMiddlewareではreplaceかどうか判別不能なので、navigateToでreplaceする場合はこのフラグをtrueにする
    const backed = ref(false); // history.back()を使った場合はtrueにする

    const pushStack = (path: string) => {
      historyStack.value.push(path);
    };

    const popStack = () => {
      return historyStack.value.pop();
    };

    const getLast = () => {
      return historyStack.value[historyStack.value.length - 1];
    };

    const back = (count: number = 1) => {
      let lastPath: string | undefined;
      for (let i = 0; i < count; i++) {
        const path = popStack();
        if (path) {
          lastPath = path;
        }
      }
      if (lastPath) {
        backed.value = true;
        navigateTo(lastPath, { replace: true });
      }
    };

    const to = async (to: RouteLocationRaw | undefined | null, options?: NavigateToOptions) => {
      replaced.value = options?.replace ?? false;
      return await navigateTo(to, options);
    };

    return {
      historyStack,
      replaced,
      backed,
      pushStack,
      getLast,
      back,
      to,
    };
  },
  // セッションストアに保存
  {
    persist: {
      storage: persistedState.sessionStorage,
    },
  },
);
