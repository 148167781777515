// 講座タイプ
export const PLAN_TYPE_INVESTMENT = "INVESTMENT"; // 投資
export const PLAN_TYPE_INVESTMENT_LABEL = "投資";
export const PLAN_TYPE_BUSINESS = "BUSINESS"; // ビジネス
export const PLAN_TYPE_BUSINESS_LABEL = "ビジネス";
export type PLAN_TYPE = typeof PLAN_TYPE_INVESTMENT | typeof PLAN_TYPE_BUSINESS;
export const PLAN_TYPE_LIST = [PLAN_TYPE_INVESTMENT, PLAN_TYPE_BUSINESS];
export const PLAN_TYPE_LABEL_LIST = [PLAN_TYPE_INVESTMENT_LABEL, PLAN_TYPE_BUSINESS_LABEL];
export const getPlanTypeLabel = (type: PLAN_TYPE) => {
  return PLAN_TYPE_LABEL_LIST[PLAN_TYPE_LIST.indexOf(type)];
};
export const PLAN_TYPE_DEFAULT = PLAN_TYPE_INVESTMENT; // デフォルトの講座タイプ

// 配信ステータス。 unpublished:下書き, scheduled:配信前, live:配信中, live_stopped:配信停止, archived:アーカイブ済み, deleted:消失, ※配信前は、生配信スケジュールのレスポンス用のステータスになります
export type LIVE_STATUS = "unpublished" | "scheduled" | "live" | "live_stopped" | "archived" | "deleted";

// 配信系のコンテンツタイプ
export const enum STREAMING_CONTENT_TYPE {
  LIVE = "live_movie",
  SCHEDULE = "schedule",
  ARCHIVE = "archive_movie",
}

// 標準のページ内の表示件数
export const DEFAULT_PAGE_SIZE = 10;

// 講座、オーナーページ内の表示件数
export const PLAN_OWNER_PAGE_SIZE = 10;

// コンテンツページ内の表示件数
export const CONTENT_PAGE_SIZE = 20;

// 講座概要体験談数
export const PLAN_SUMMARY_REVIEW_SIZE = 3;

// コンテンツのソート
// random指定時の別ページ遷移時にはresponseのsortの文字列を入れる（nullの場合desc)
export type CONTENT_SORT_TYPE = "desc" | "asc" | "random" | string | undefined;

// コンテンツの公開ステータス。PUBLISH:公開, LIMITED:限定公開, PRIVATE:非公開
export const CONTENT_PUBLISH_STATUS = ["PUBLISH", "LIMITED", "PRIVATE"];
export const CONTENT_PUBLISH_STATUS_LABELS = ["公開", "限定公開", "非公開"];

export const getPublishStatusLabel = (status: string) => {
  const index = CONTENT_PUBLISH_STATUS.indexOf(status);
  if (index === -1) {
    return "";
  }
  return CONTENT_PUBLISH_STATUS_LABELS[index];
};
