<script setup lang="ts">
  import { PlanInfo } from "~/entities/PlanInfo";
  import { usePlanStore } from "~/stores/PlanStore";
  import { useLoginStore } from "~/stores/LoginStore";
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { usePushQuayStore } from "~/stores/PushQuayStore";
  import PushQuayRegisterModal from "~/components/Atoms/PushQuayRegisterModal.vue";
  import PushQuayRegisterCompleteModal from "~/components/Atoms/PushQuayRegisterCompleteModal.vue";

  const planStore = usePlanStore();
  const loginStore = useLoginStore();
  const snackBarStore = useSnackBarStore();
  const pushQuayStore = usePushQuayStore();

  interface Props {
    planInfo: PlanInfo;
  }
  const props = defineProps<Props>();

  // 連打防止
  const loading = ref(false);
  // 連打対策用、前回のクリック時刻
  const lastClickTime = ref(0);
  // 連打対策用、クリック間隔
  const clickInterval = 1000;

  // PushQuay 通知登録モーダル
  const pushQuayRegisterModal = ref<InstanceType<typeof PushQuayRegisterModal> | null>(null);
  const pushQuayRegisterCompleteModal = ref<InstanceType<typeof PushQuayRegisterCompleteModal> | null>(null);

  // 連打対策を入れるので、ボタンクリック時の処理を関数にまとめる
  const onClickNotificationButton = async (register: boolean) => {
    if (loginStore.isLogin() === false) {
      snackBarStore.setError("PushQuay 通知を受け取るにはログインしてください");
      return;
    }

    // 連打防止
    const now = new Date().getTime();
    if (now - lastClickTime.value < clickInterval) {
      return;
    }
    if (loading.value) {
      return;
    }
    loading.value = true;

    try {
      if (register) {
        pushQuayRegisterModal.value?.openModal();
      } else {
        await unregisterPushQuayNotification();
      }
    } finally {
      // 連打防止
      lastClickTime.value = now;
      loading.value = false;
    }
  };

  const onRegister = () => {
    // トークン待ちのため、登録完了モーダルを表示
    pushQuayRegisterCompleteModal.value?.openModal();
  };

  const unregisterPushQuayNotification = async () => {
    try {
      await pushQuayStore.unregisterPushQuayNotification(props.planInfo.id);
      snackBarStore.set(`${props.planInfo.title}の PushQuay 通知解除が完了しました`);
    } catch (error: any) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("PushQuay 通知の解除に失敗しました");
      sentryErrorLog(`PushQuay 通知の解除に失敗: ${error.message}`);
      return;
    }

    // 成功時は講座を再度読み込んで最新状態を表示
    planStore.fetchOne(props.planInfo.id);
  };

  const isSupported = computed(() => {
    return pushQuayStore.isPushQuaySupported();
  });
</script>

<template>
  <div class="push-quay-notification-btn-wrapper" data-testid="push-quay-notification-btn">
    <div class="text-center">
      <v-btn
        :disabled="!isSupported"
        size="small"
        class="push-quay-notification-btn text-center rounded px-2"
        :class="{
          'push-quay-notification-btn-active': isSupported && !planInfo.isPushQuayLinked,
          'push-quay-notification-btn-inactive': !isSupported || planInfo.isPushQuayLinked,
        }"
        @click="onClickNotificationButton(!planInfo.isPushQuayLinked)"
      >
        <template v-if="planInfo.isPushQuayLinked">
          <img src="@/assets/images/icon/push-off.svg" class="mr-1" :width="18" alt="push" />
          PushQuay 通知解除
        </template>
        <template v-else>
          <img src="@/assets/images/icon/push-on.svg" class="mr-1" :width="18" alt="push" />
          PushQuay 通知登録
        </template>
      </v-btn>

      <div v-if="!isSupported" class="text-red text-left mt-2">
        この端末では PushQuay 通知登録/解除できません。<br />
        iOS または Android でご利用ください。
      </div>
    </div>
    <AtomsPushQuayRegisterModal ref="pushQuayRegisterModal" @register="onRegister" />
    <AtomsPushQuayRegisterCompleteModal ref="pushQuayRegisterCompleteModal" :plan-info="planInfo" />
  </div>
</template>

<style lang="scss" scoped>
  .push-quay-notification-btn-wrapper {
    display: flex;
    align-items: center;
  }
  .push-quay-notification-btn {
    width: 280px;
    height: 56px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8em;
    letter-spacing: 0.06em;
    &.push-quay-notification-btn-active {
      background-image: linear-gradient(60deg, #005bc6 35%, red 82%, #fed801);
    }
    &.push-quay-notification-btn-inactive {
      background-color: #aaaaaa;
    }
  }
</style>
