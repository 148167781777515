<script setup lang="ts">
  import { PlanInfo } from "~/entities/PlanInfo";
  import { usePlanStore } from "~/stores/PlanStore";
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { usePushQuayStore } from "~/stores/PushQuayStore";

  const planStore = usePlanStore();
  const snackBarStore = useSnackBarStore();
  const pushQuayStore = usePushQuayStore();

  interface Props {
    planInfo: PlanInfo;
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  const props = defineProps<Props>();

  const modal = ref(false);

  const openModal = () => {
    modal.value = true;
  };

  defineExpose({
    openModal,
  });

  // トークン送信可否フラグ
  // トークン文字数は仕様公開されていないが、実績ベースで 142,159,163 のいずれかなので、100文字以上で送信可能としておく
  const canSendToken = computed(() => pushQuayToken.value.length >= 100);
  // トークンデータ
  const pushQuayToken = ref("");
  // モーダル開いたときにトークンデータ初期化
  watch(modal, value => {
    if (value) {
      pushQuayToken.value = "";
    }
  });

  // iOS の場合、トークン貼り付け時に自動的にこちらが呼ばれて通知登録を行う
  const onPasteToken = async (event: ClipboardEvent) => {
    // iOS でペースト時に勝手に変換されるのを防ぐ処理
    event.preventDefault(); // デフォルトのペースト動作を防ぐ
    const pastedText = event.clipboardData!.getData("text"); // クリップボードのテキストを取得
    document.execCommand("insertText", false, pastedText); // そのまま挿入

    // 通知登録実行
    await registerPushNotification(pastedText);
  };

  // Android の場合、トークン貼り付けトリガーを拾えないのでボタン押下時にこちらが呼ばれて通知登録を行う
  const onFinishRegistration = async () => {
    // 通知登録実行
    await registerPushNotification(pushQuayToken.value);
  };

  const registerPushNotification = async (token: string) => {
    try {
      await pushQuayStore.registerPushQuayToken(token, true);
    } catch (error: any) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("PushQuay 通知の登録に失敗しました");
      sentryErrorLog(`PushQuay 通知の登録に失敗(registerPushQuayToken): ${error.message}`);
      return;
    }

    try {
      await pushQuayStore.registerPushQuayNotification(props.planInfo.id);
      snackBarStore.set(`${props.planInfo.title}の PushQuay 通知登録が完了しました`);
    } catch (error: any) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("PushQuay 通知の登録に失敗しました");
      sentryErrorLog(`PushQuay 通知の登録に失敗(registerPushQuayNotification): ${error.message}`);
      return;
    }

    // モーダル閉じる
    modal.value = false;

    // 成功時は講座を再度読み込んで最新状態を表示
    planStore.fetchOne(props.planInfo.id);
  };
</script>

<template>
  <v-dialog v-model="modal" width="906" scrim="black" :attach="!!isTest">
    <v-card class="dialog">
      <v-btn class="closeBtn" icon="$close" variant="text" @click="modal = false"></v-btn>

      <div class="container">
        <div>
          <div>PushQuay アプリでコピーしたトークンを貼り付けてください。</div>
          <v-text-field v-model="pushQuayToken" label="" class="mt-2" @paste="onPasteToken" />
        </div>

        <div class="w-100 text-center my-4">
          <AtomsDialogButton :disabled="!canSendToken" @click="onFinishRegistration"
            >PushQuay 通知登録を実行</AtomsDialogButton
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";

  .dialog {
    position: relative;
    text-align: center;
    background-color: #222222;
    padding: 40px 60px;
    @include display_sp {
      padding: 40px 20px;
    }
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .divider {
      margin: 20px 20%;
      border-bottom: 1px solid rgba(var(--v-theme-monochrome-01), 0.1);
    }
  }
</style>
