export type NotificationSettingNotificationType = "webpush" | "line" | "pushquay";
export type NotificationSettingContentsType = "live_movie" | "movie" | "document" | "article" | "talk_room";
export type NotificationSettingContentsSubType = "talk_dm" | "talk_mention";

export class NotificationSettingItem {
  constructor(
    public notificationType: NotificationSettingNotificationType,
    public contentsType: NotificationSettingContentsType,
    public contentsSubType: NotificationSettingContentsSubType | null,
    public settingName: string,
    public roomId: number | null,
    public enable: boolean,
  ) {}

  static forPutContentsSetting(
    notificationType: NotificationSettingNotificationType,
    contentsType: NotificationSettingContentsType,
  ) {
    return new NotificationSettingItem(notificationType, contentsType, null, "", 0, true);
  }

  static forPutTalkRoomSetting(
    notificationType: NotificationSettingNotificationType,
    contentsSubType: NotificationSettingContentsSubType | null,
    roomId: number | null,
  ) {
    return new NotificationSettingItem(notificationType, "talk_room", contentsSubType, "", roomId, true);
  }
}

export class NotificationSettings {
  constructor(
    public webpushSettings: NotificationSettingItem[],
    public lineSettings: NotificationSettingItem[],
    public pushQuaySettings: NotificationSettingItem[],
  ) {}
}
