import type { IPushQuayRepository } from "~/interfaces/IPushQuayRepository";
import type { IHttpResponse } from "~/interfaces/IHttpResponse";

export class PushQuayRepository implements IPushQuayRepository {
  async registerPushQuayToken(token: string): Promise<void> {
    const body = {
      token,
    };
    const res = await PutRequest<IHttpResponse>("/pushquay/token", JSON.stringify(body), true);
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed put /pushquay/token: response=${JSON.stringify(res)}`);
    }
  }

  async registerPushQuayNotification(planId: string): Promise<void> {
    const body = {
      plan_id: planId,
    };
    const res = await PostRequest<IHttpResponse>("/pushquay/plan", JSON.stringify(body));
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed post /pushquay/plan: planId=${planId}, response=${JSON.stringify(res)}`);
    }
  }

  async unregisterPushQuayNotification(planId: string): Promise<void> {
    const res = await DeleteRequest<IHttpResponse>(`/pushquay/plan/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed delete /pushquay/plan/${planId}: response=${JSON.stringify(res)}`);
    }
  }
}
