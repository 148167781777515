<script setup lang="ts">
  interface Props {
    isTest?: boolean; // テスト時のみ true にする。でないと v-dialog を find できないため
  }
  defineProps<Props>();

  interface Emits {
    (event: "register"): void;
  }
  const emit = defineEmits<Emits>();

  const modal = ref(false);

  const openModal = () => {
    modal.value = true;
  };

  defineExpose({
    openModal,
  });

  const config = useRuntimeConfig().public;

  const deepLinkBase = computed(() => {
    return (isIOS() ? "https://app-ios." : "https://app-android.") + config.PUSH_QUAY_ROOT_DOMAIN;
  });

  const installLink = computed(() => {
    return `${deepLinkBase.value}/signin`;
  });

  const registerLink = computed(() => {
    const randomId = Math.floor(Math.random() * 1000000).toString();
    const appId = config.PUSH_QUAY_FINCS_APP_ID;
    // 自動遷移でなく手動遷移とする
    // https://fincs.backlog.com/view/FINCS-3527
    // return `${deepLinkBase.value}/request_token/${randomId}?return_url=${returnUrl}&app=${appId}`;
    return `${deepLinkBase.value}/request_token/${randomId}?app=${appId}`;
  });

  const onInstall = () => {
    window.location.href = installLink.value;
  };

  const onRegister = () => {
    modal.value = false;
    window.location.href = registerLink.value;
    emit("register");
  };
</script>

<template>
  <v-dialog v-model="modal" width="906" scrim="black" :attach="!!isTest">
    <v-card class="dialog">
      <v-btn class="closeBtn" icon="$close" variant="text" @click="modal = false"></v-btn>

      <div class="container">
        <div class="text-dialog-title mb-4">PushQuay通知の受け取り方法</div>
        <div class="text-dialog-description text-left">
          <div>通知登録前にPushQuayアプリをインストールしてサインインする必要があります。</div>
          <div v-if="isIOS()" class="text-main-04">
            ※iOS16未満では、PushQuayアプリをインストールできません。バージョンアップをお願いします。
          </div>
          <div class="w-100 text-center my-4">
            <AtomsDialogButton @click="onInstall">PushQuayアプリをインストール</AtomsDialogButton>
          </div>
        </div>

        <div class="divider"></div>

        <div class="text-dialog-title mb-4">PushQuayアプリインストール済み・サインイン済みの方</div>
        <div class="text-dialog-description">
          <MoleculesExpansionPanel>
            <template #title>通知登録の手順を見る</template>
            <template #default>
              <div class="my-2">1. 下記「PushQuay通知用トークンを取得」をタップします。</div>
              <div class="my-2">
                2.
                PushQuayアプリが自動起動してプッシュ通知受信用トークン画面が開きます。そこで「トークンをコピー」をタップします。
              </div>
              <div class="my-2">3. アプリ切替でFincs画面へ戻り、コピーしたトークンをペーストします。</div>
              <div class="my-2">
                4. このタイミングで自動的に通知登録が実行されますが、もしされない場合は「PushQuay
                通知登録を実行」をタップします。
              </div>
            </template>
          </MoleculesExpansionPanel>

          <div class="w-100 text-center my-4">
            <AtomsDialogButton @click="onRegister">PushQuay通知用トークンを取得</AtomsDialogButton>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
  @import "assets/media.scss";

  .dialog {
    position: relative;
    text-align: center;
    background-color: #222222;
    padding: 40px 60px;
    @include display_sp {
      padding: 40px 20px;
    }
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .divider {
      margin: 20px 20%;
      border-bottom: 1px solid rgba(var(--v-theme-monochrome-01), 0.1);
    }
  }
</style>
