import { Tag } from "./Tag";
import { PlanInfo } from "./PlanInfo";
import { OwnerInfo } from "./OwnerInfo";

export interface ArticleFileItem {
  fileKey: string;
  fileSubUrl: string;
  fileUrl: string;
}

// 記事情報
export class ArticleInfo {
  constructor(
    public id: string = "",
    public thumbnailImage: string = "", // サムネイル画像
    public thumbnailKey: string = "",
    public contentsTitle: string = "", // タイトル
    public description: string = "", // タイトル
    public contentsUrl: string = "", // ファイルパス
    public contentsKey: string = "",
    public tags: Tag[] = [],
    public postDateTime: string = "", // 投稿日
    public isFree: boolean = false,
    public isFeatured: boolean = false,
    public isContract: boolean = false,
    public isComplete: boolean = false,
    public unread: boolean = false,

    public plan: PlanInfo | undefined = undefined,
    public planOwner: OwnerInfo | undefined = undefined, // オーナー

    public contentsType: string = "",
    public isUnread: boolean = false,
    public isOwner: boolean = false,
    public isGood: boolean = false, // 自分がいいねしているか
    public goodCount: number = 0, // いいね数

    public articleBody: string = "", // マークダウン形式の説明
    public articleFiles: ArticleFileItem[] = [], // 記事ファイルのキー
    public articleStatus: "PUBLISH" | "DRAFT" = "DRAFT", // 下書きか本番か

    public publishStatus: "PUBLISH" | "PRIVATE" | "LIMITED" = "PRIVATE", // 公開設定
    public publishDateTime: string = "", // 公開日
    public postUserId: string = "", // 投稿者ID
  ) {}
}
