// middleware/history.js
import { useHistoryStore } from "~/stores/HistoryStore";

export default defineNuxtRouteMiddleware((to, from) => {
  const historyStore = useHistoryStore();

  // replaceの場合は履歴に追加しない
  if (historyStore.replaced) {
    historyStore.replaced = false;
    return;
  }

  // backedの場合は履歴に追加しない
  if (historyStore.backed) {
    historyStore.backed = false;
    return;
  }
  // リロード時は履歴に追加しない
  if (from.fullPath === to.fullPath) {
    return;
  }
  // 外部サイトから戻ってきた場合には履歴に追加しない
  if (to.query?.fromExternal === "true") {
    return;
  }

  historyStore.pushStack(from.fullPath);
});
