import type { IUserRepository } from "~/interfaces/IUserRepository";
import { UserInfo } from "~/entities/UserInfo";
import { ContractMember } from "~/entities/ContractMember";
import type { MailRefusalResponse } from "~/entities/MailRefusalResponse";

export class UserRepository implements IUserRepository {
  /**
   * 指定のユーザーの情報を取得する
   */
  async fetch(userId: string): Promise<UserInfo> {
    const response = await GetRequest<{ statusCode: Number; userDetail: UserInfo }>(`/user/${userId}`);
    return response?.userDetail;
  }

  /**
   * ユーザーの情報を更新する
   */
  async update(userInfo: UserInfo): Promise<void> {
    const res = await PutRequest<{ statusCode: number }>(
      "/user/basic",
      JSON.stringify({
        user_name: userInfo.userName,
        description: userInfo.userDescription,
        investor_title: userInfo.investorTitle,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("UserRepository.update API Error");
    }
  }

  /**
   * ユーザーのアイコン画像を更新する
   */
  async uploadIcon(imageFile: File): Promise<void> {
    const form = new FormData();
    form.append("icon", imageFile);
    const res = await PutRequest<{ statusCode: number }>("/user/upload_icon", form);
    if (!res || res.statusCode !== 200) {
      throw new Error("UserRepository.uploadIcon API Error");
    }
  }

  /**
   * ユーザーの契約情報を取得する
   */
  async contractInfo(planId: string, noErrorDialog?: boolean): Promise<ContractMember> {
    return await GetRequest<any>(`/user/contract_info/${planId}`, noErrorDialog);
  }

  async getMailRefusal(): Promise<MailRefusalResponse> {
    const res = await GetRequest<any>("/user/mail_refusal");
    return res;
  }

  async setMailRefusal(accountId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      "/user/mail_refusal",
      JSON.stringify({ account_id: accountId }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("UserRepository.setMailRefusal API Error");
    }
  }

  async deleteMailRefusal(accountId: string): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number }>(`/user/mail_refusal/${accountId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("UserRepository.deleteMailRefusal API Error");
    }
  }

  async setMailRefusalWithToken(userId: string, accountId: string, token: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      `/mail_refusal/${userId}/${accountId}/${token}`,
      undefined,
      true,
      false,
      true,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("UserRepository.setMailRefusalWithToken API Error");
    }
  }
}
