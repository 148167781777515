import { DistributionFile } from "./DistributionFile";
import { DocumentInfo } from "./DocumentInfo";
import { LiveInfo } from "./LiveInfo";
import { MovieInfo } from "./MovieInfo";
import { ArticleInfo } from "./ArticleInfo";
import { PlanInfo } from "./PlanInfo";
import { Tag } from "./Tag";
import { Library } from "./Library";
import { type ArticleFileItem } from "./ArticleInfo";

export type DistributionType = "live" | "livearchive" | "movie" | "document" | "article";

export class DistributionInfo {
  public type: DistributionType;
  public title: string;
  public detail: string;
  public contentFile: DistributionFile;
  public thumbnailFile: DistributionFile;
  public tags: Tag[];
  public libraries: Library[];
  public distributeNow: boolean;
  public distributeAt: string | null;
  public isFree: boolean;
  public isFeatured: boolean;
  public planId: string;
  public contentsId: string;
  // コンテンツキー
  public contentsKey: string;
  public thumbnailKey: string;
  public moviePlayTimeSec: number | null;
  // 公開設定
  public publishStatus: "PUBLISH" | "PRIVATE" | "LIMITED";
  // 投稿講師ユーザーID
  public postUserId: string | null = null;
  // 記事内容
  public articleBody: string | null = null;
  // 記事ファイルコンテンツキーリスト
  public articleFiles: ArticleFileItem[] = [];
  // 下書きかどうか
  public articleStatus: "PUBLISH" | "DRAFT" = "DRAFT"; // 下書きか本番か

  constructor(
    type: DistributionType = "live",
    title = "",
    detail = "",
    contentFile = new DistributionFile(),
    thumbnailFile = new DistributionFile(),
    tags: Tag[] = [],
    libraries: Library[] = [],
    distributeNow = true,
    distributeAt: string | null = null,
    isFree = false,
    isFeatured = false,
    planId = "",
    contentsId = "",
    contentsKey = "",
    thumbnailKey = "",
    moviePlayTimeSec: number | null = null,
    publishStatus: "PUBLISH" | "PRIVATE" | "LIMITED" = "PUBLISH",
    postUserId: string | null = null,
    articleBody: string | null = null,
    articleFiles: ArticleFileItem[] = [],
    articleStatus: "PUBLISH" | "DRAFT" = "DRAFT",
  ) {
    this.type = type;
    this.title = title;
    this.detail = detail;
    this.contentFile = contentFile;
    this.thumbnailFile = thumbnailFile;
    this.tags = tags;
    this.libraries = libraries;
    this.distributeNow = distributeNow;
    this.distributeAt = distributeAt;
    this.isFree = isFree;
    this.isFeatured = isFeatured;
    this.planId = planId;
    this.contentsId = contentsId;
    this.contentsKey = contentsKey;
    this.thumbnailKey = thumbnailKey;
    this.moviePlayTimeSec = moviePlayTimeSec;
    this.publishStatus = publishStatus;
    this.postUserId = postUserId;
    this.articleBody = articleBody;
    this.articleFiles = articleFiles;
    this.articleStatus = articleStatus;
  }

  // LiveInfoを作成する
  public toLiveInfo(planInfo: PlanInfo): LiveInfo {
    return new LiveInfo(
      this.contentsId,
      this.title,
      "schedule",
      this.detail,
      undefined,
      this.thumbnailFile.previewImgSrc,
      [],
      this.tags,
      "",
      "",
      "",
      "scheduled",
      0,
      0,
      planInfo.planOwner,
      false,
      false,
      true,
      planInfo,
      this.isFree,
      this.isFeatured,
      true,
      "",
      0,
      false,
      this.contentsKey,
      this.thumbnailKey,
      true,
      false,
      0,
      [],
      false,
      this.publishStatus,
      "",
      "",
    );
  }

  // ArticleInfoを作成する
  public toArticleInfo(planInfo: PlanInfo): ArticleInfo {
    return new ArticleInfo(
      this.contentsId,
      this.thumbnailFile.previewImgSrc,
      this.thumbnailKey,
      this.title,
      this.detail,
      "",
      this.contentsKey,
      this.tags,
      "",
      this.isFree,
      false,
      false,
      false,
      false,
      planInfo,
      planInfo.planOwner,
      "article",
      false,
      false,
      false,
      0,
      this.articleBody || "",
      this.articleFiles,
      this.articleStatus,
      this.publishStatus,
      this.distributeAt || "",
      this.postUserId || "",
    );
  }

  // 初期値
  static init(type: DistributionType, planId: string): DistributionInfo {
    return new DistributionInfo(
      type,
      "",
      "",
      new DistributionFile(),
      new DistributionFile(),
      [],
      [],
      true,
      null,
      false,
      false,
      planId,
    );
  }

  // LiveInfo から作成する
  static fromLiveInfo(liveInfo: LiveInfo, type: DistributionType = "live"): DistributionInfo {
    return new DistributionInfo(
      type,
      liveInfo.contentsTitle,
      liveInfo.description,
      new DistributionFile(),
      new DistributionFile("", liveInfo.thumbnailImage, liveInfo.thumbnailKey), // 表示するためpreviewImgSrcにurlを入れておく
      liveInfo.tags,
      liveInfo.libraries, // TODO:API追加依頼中
      false,
      liveInfo.liveScheduleDateTime,
      liveInfo.isFree,
      liveInfo.isFeatured,
      liveInfo.plan?.id,
      liveInfo.id,
      liveInfo.contentsKey,
      liveInfo.thumbnailKey,
      null,
      liveInfo.publishStatus,
    );
  }

  // MovieInfo から作成する
  static fromMovieInfo(movieInfo: MovieInfo, previewImgSrc: string): DistributionInfo {
    return new DistributionInfo(
      "movie",
      movieInfo.contentsTitle,
      movieInfo.description,
      new DistributionFile("", previewImgSrc, movieInfo.contentsKey), // 表示するためpreviewImgSrcにurlを入れておく
      new DistributionFile("", movieInfo.thumbnailImage, movieInfo.thumbnailKey), // 表示するためpreviewImgSrcにurlを入れておく
      movieInfo.tags,
      movieInfo.libraries, // TODO:API追加依頼中
      false,
      null,
      movieInfo.isFree,
      movieInfo.isFeatured,
      movieInfo.plan?.id,
      movieInfo.id.toString(),
      movieInfo.contentsKey,
      movieInfo.thumbnailKey,
      movieInfo.moviePlayTimeSec,
      movieInfo.publishStatus,
    );
  }

  // DocumentInfo から作成する
  static fromDocumentInfo(documentInfo: DocumentInfo, previewImgSrc: string): DistributionInfo {
    return new DistributionInfo(
      "document",
      documentInfo.contentsTitle,
      documentInfo.description,
      new DistributionFile("", previewImgSrc, documentInfo.contentsKey), // 表示するためpreviewImgSrcにurlを入れておく
      new DistributionFile("", documentInfo.thumbnailImage, documentInfo.thumbnailKey), // 表示するためpreviewImgSrcにurlを入れておく
      documentInfo.tags,
      documentInfo.libraries, // TODO:API追加依頼中
      false,
      null,
      documentInfo.isFree,
      documentInfo.isFeatured,
      documentInfo.plan?.id,
      documentInfo.id.toString(),
      documentInfo.contentsKey,
      documentInfo.thumbnailKey,
      null,
      documentInfo.publishStatus,
    );
  }

  // ArticleInfo から作成する
  static fromArticleInfo(articleInfo: ArticleInfo): DistributionInfo {
    return new DistributionInfo(
      "article",
      articleInfo.contentsTitle,
      articleInfo.description,
      new DistributionFile(),
      new DistributionFile("", articleInfo.thumbnailImage, articleInfo.thumbnailKey), // 表示するためpreviewImgSrcにurlを入れておく
      articleInfo.tags,
      [],
      false,
      articleInfo.publishDateTime,
      articleInfo.isFree,
      articleInfo.isFeatured,
      articleInfo.plan?.id.toString(),
      articleInfo.id.toString(),
      articleInfo.contentsKey,
      articleInfo.thumbnailKey,
      null,
      articleInfo.publishStatus,
      articleInfo.postUserId,
      articleInfo.articleBody,
      articleInfo.articleFiles,
      articleInfo.articleStatus,
    );
  }
}
