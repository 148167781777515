<script setup lang="ts">
  import { useDashboardTabStore } from "~/stores/DashboardTabStore";
  import { useLoginStore } from "~/stores/LoginStore";
  import { useUserStore } from "~/stores/UserStore";
  import { useDocumentStore } from "~/stores/DocumentStore";
  import { useMovieStore } from "~/stores/MovieStore";
  import { useLiveStore } from "~/stores/LiveStore";
  import { useLiveArchiveStore } from "~/stores/LiveArchiveStore";
  import { useLiveScheduleStore } from "~/stores/LiveScheduleStore";
  import { useAgoraStore } from "~/stores/AgoraStore";
  import { useNavigationStore } from "~/stores/NavigationStore";

  import { usePlanStore } from "~/stores/PlanStore";
  import { PlanInfo } from "~/entities/PlanInfo";
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { useHistoryStore } from "~/stores/HistoryStore";

  const historyStore = useHistoryStore();

  const userStore = useUserStore();
  const loginStore = useLoginStore();
  const planStore = usePlanStore();
  const documentStore = useDocumentStore();
  const movieStore = useMovieStore();
  const liveStore = useLiveStore();
  const liveScheduleStore = useLiveScheduleStore();
  const liveArchiveStore = useLiveArchiveStore();
  const dashboardTabStore = useDashboardTabStore();
  const navigationStore = useNavigationStore();

  // agora制御Store
  const agoraStore = useAgoraStore();

  defineProps<{
    headerHeight: number;
  }>();

  const isLogin = computed<boolean>(() => loginStore.isLogin());
  const isOwner = computed<boolean>(() => loginStore.isOwner());

  const showBackButton = computed<boolean>(() => {
    const name = useRoute().name;
    const pathNames = [
      "plan-id-movie-contentId", // 動画詳細 /plan/{planid}/movie/{contentId}
      "plan-id-document-contentId", // 資料詳細 /plan/{planid}/document/{contentId}
      "plan-id-livearchive-contentId", // 配信詳細 /plan/{planid}/livearchive/{contentId}
      "plan-id-live-contentId", // 配信予約詳細 /plan/{planid}/liveschedule/{contentId}
      "plan-id-liveschedule-contentId", // 配信予約詳細 /plan/{planid}/liveschedule/{contentId}
      "plan-id-selection-selectionId", // 配信予約詳細 /plan/{planid}/selection/{selectionId}
      "plan-id-article-contentId", // 記事詳細 /plan/{planid}/article/{contentId}
    ];
    return pathNames.includes(String(name || ""));
  });

  // TOPページかどうか
  const isTopPage = computed<boolean>(() => {
    return useRoute().name === "index";
  });

  // 総合トップへ戻る
  const onClickFincsLogo = () => {
    // タブを初期位置に戻す
    dashboardTabStore.initTab();
    // TOPへ戻る
    historyStore.to("/");
  };

  // レスポンシブ対応
  const isSp = isSpDisplayRef();
  const isTabletAndSp = isTabletAndSpDisplayRef();

  // 配信終了ボタンを表示するかどうか
  const showLiveLeaveButton = computed<boolean>(() => {
    if (useRoute().path.includes("/live/") && useRoute().path.includes("/host")) {
      return agoraStore.isJoined;
    }
    return false;
  });

  // Planがあれば返す
  const currentPlan = computed<PlanInfo | null>(() => {
    if (useRoute().path.includes("/movie/") && movieStore.movie?.plan) {
      return movieStore.movie?.plan;
    } else if (useRoute().path.includes("/document/") && documentStore.document?.plan) {
      return documentStore.document?.plan;
    } else if (useRoute().path.includes("/live/") && liveStore.live?.plan) {
      return liveStore.live?.plan;
    } else if (useRoute().path.includes("/liveschedule/") && liveScheduleStore.liveSchedule?.plan) {
      return liveScheduleStore.liveSchedule?.plan;
    } else if (useRoute().path.includes("/livearchive/") && liveArchiveStore.liveArchive?.plan) {
      return liveArchiveStore.liveArchive?.plan;
    } else if (useRoute().path.includes("/selection/") && planStore.plan) {
      return planStore.plan;
    } else if (useRoute().path.includes("/plan/") && planStore.plan) {
      return planStore.plan;
    }
    return null;
  });

  // planTitleの長さ調整
  const truncatedPlanTitle = computed<string | null>(() => {
    if (!currentPlan.value) return null;
    const planTitleLenght = 17;
    const ommision = "...";
    if (currentPlan.value.title.length <= planTitleLenght) return currentPlan.value.title;
    return currentPlan.value.title.substring(0, planTitleLenght) + ommision;
  });

  // 配信するボタンを表示するかどうか
  const showDistributionButton = computed<boolean>(() => {
    // コンテンツのpath構成が/plan/{planId}/以下に変更になったので、それぞれのpathをチェックする
    const checkPathList = ["/document/", "/live/", "/livearchive/", "/liveschedule/", "/movie/"];
    if (
      checkPathList.some(path => {
        return useRoute().path.includes(path);
      })
    ) {
      return false;
    }
    if (useRoute().path.includes("/plan/") && planStore.plan) {
      return planStore.plan.isOwner;
    }
    return false;
  });

  // 配信選択ダイアログ用
  const isDistributionSelectionOpened = ref(false);
  const openDistributionSelection = () => {
    isDistributionSelectionOpened.value = true;
  };

  // 講座クリック
  const onClickPlan = () => {
    historyStore.to(`/plan/${currentPlan.value?.id}?tab=overview`);
  };

  // 通知関連
  const snackBarStore = useSnackBarStore();
  const usingNotification = computed<boolean>(() => {
    return !!(currentPlan.value?.isWebPushLinked || currentPlan.value?.isPushQuayLinked);
  });
  const notificationIconImgSize = computed<number>(() => {
    return usingNotification.value ? 28 : 20;
  });
  const notificationIconImgPath = computed<string>(() => {
    return `assets/images/icon/${usingNotification.value ? "push-on-primary" : "push-off"}.svg`;
  });
  const isNotificationSettingOpened = ref(false);
  const openNotificationSetting = () => {
    if (!isLogin.value) {
      snackBarStore.setError("通知を受け取るにはログインしてください");
      return;
    }
    isNotificationSettingOpened.value = true;
  };

  const handleBack = () => {
    historyStore.back();
  };

  // 講座タイプの変更を監視
  watch(
    () => currentPlan.value?.planType,
    (newValue, _oldValue) => {
      navigationStore.setPlanType(newValue);
    },
  );

  onMounted(() => {
    // 講座タイプのチェック
    navigationStore.setPlanType(currentPlan.value?.planType);
  });
</script>

<template>
  <v-app-bar
    v-if="headerHeight > 0"
    color="background"
    class="pt-4"
    :style="`position: fixed; height: ${headerHeight}px`"
  >
    <div class="d-flex justify-space-between align-center w-100">
      <!-- ヘッダー左側の要素。要素の中身によって横幅が変わるとズレるので横幅を固定している -->
      <v-sheet class="d-flex justify-start align-center" color="transparent">
        <template v-if="!isSp || !showBackButton">
          <div
            v-if="isOwner"
            class="mx-sm-6 d-flex flex-column cursor-pointer"
            :class="isSp ? `mx-2` : `ml-4`"
            @click="onClickFincsLogo"
          >
            <AtomsFincsLogoSvg class="mb-2" :width="isSp ? 72 : 120" />
            <p :class="{ owner_badge: isSp }" class="text-subtitle-2 text-center bg-main-01 rounded d-inline-block">
              講師専用
            </p>
          </div>
          <div
            v-else
            class="d-flex flex-column cursor-pointer mx-sm-6"
            :class="isSp ? `mx-2` : `ml-4 mb-2`"
            @click="onClickFincsLogo"
          >
            <AtomsFincsLogoSvg :width="isSp ? 72 : 138" />
          </div>
        </template>
        <OrganismsMainHeaderPlanType :enable-select="isTopPage" />
        <AtomsButton v-if="showBackButton" :width="isSp ? 88 : 60" :height="30" color="primary" @click="handleBack">
          <v-icon>$mdiChevronLeft</v-icon>
          戻る
        </AtomsButton>
      </v-sheet>

      <!-- ヘッダー中央の要素。Planページならヘッダーに値を出す -->
      <div v-if="currentPlan" class="d-flex plan-info" style="cursor: pointer" @click="onClickPlan">
        <v-img
          v-if="!!currentPlan.thumbnailImage"
          :src="currentPlan.thumbnailImage"
          cover
          class="plan-img rounded"
          data-testid="header-plan-thumbnail"
        />
        <div v-if="!isSp" class="plan-title ml-2">{{ truncatedPlanTitle }}</div>

        <!-- 通知設定アイコン -->
        <AtomsSvgIcon
          :width="notificationIconImgSize"
          :margin-x="8"
          :img-path="notificationIconImgPath"
          class="my-auto"
          :class="isSp ? 'mx-2' : 'mx-4'"
          data-testid="notification-setting"
          @click="openNotificationSetting"
        />
      </div>
      <!-- ヘッダー右側のブロック。要素の中身によって横幅が変わるとズレるので横幅を固定している -->
      <v-sheet class="d-flex align-bottom justify-end" :height="52" color="transparent">
        <template v-if="showDistributionButton">
          <v-sheet>
            <v-btn
              height="40"
              :class="{ isTabletAndSp: isTabletAndSp }"
              class="distribute-btn"
              @click="openDistributionSelection"
            >
              <div v-if="isTabletAndSp">
                <AtomsSvgIcon :width="20" :img-path="`assets/images/icon/menu/plan_delivery.svg`" />
                <div>配信</div>
              </div>
              <div v-else>配信する</div>
            </v-btn>
          </v-sheet>
          <v-spacer></v-spacer>
        </template>
        <template v-else-if="showLiveLeaveButton">
          <v-sheet>
            <v-btn
              height="40"
              :class="{ isTabletAndSp: isTabletAndSp }"
              class="live-leave-btn"
              @click="agoraStore.setOnLeaveButton(true)"
            >
              <div v-if="isSp">
                <AtomsSvgIcon :width="20" :img-path="`assets/images/icon/menu/plan_delivery.svg`" />
                <div>配信終了</div>
              </div>
              <div v-else>配信終了</div>
            </v-btn>
          </v-sheet>
          <v-spacer></v-spacer>
        </template>
        <div data-testid="main-header-login">
          <!-- ログイン状態の場合はマイページを表示する -->
          <MoleculesMainHeaderItem
            v-if="isLogin"
            class="pr-2"
            to-path="/mypage"
            :img="userStore.user?.userImage"
            :loading="userStore.loading"
            :mypage="true"
          />
          <!-- 未ログインの場合は登録/ログインを表示する -->
          <MoleculesMainHeaderItem
            v-else
            class="pr-2"
            icon="login"
            title="登録/ログイン"
            :to-path="`/login?returnPath=${encodeURIComponent(useRoute().fullPath)}`"
          />
        </div>
      </v-sheet>
    </div>
  </v-app-bar>

  <!-- 配信選択ダイアログ -->
  <OrganismsDistributionSelectionDialog
    v-model:is-open="isDistributionSelectionOpened"
    :plan-id="currentPlan?.id.toString()"
  ></OrganismsDistributionSelectionDialog>

  <!-- 通知設定ダイアログ -->
  <OrganismsNotificationSettingDialog
    v-if="currentPlan && isLogin && isNotificationSettingOpened"
    v-model:is-open="isNotificationSettingOpened"
    :plan-info="currentPlan"
  ></OrganismsNotificationSettingDialog>
</template>

<style lang="scss" scoped>
  .owner_badge {
    font-size: 12px !important;
  }
  .plan-info {
    > div {
      vertical-align: middle;
    }
    .plan-img {
      width: 71px;
      height: 40px;
      object-fit: fit;
      display: inline-block;
    }
    .plan-title {
      display: inline-block;
      font-size: 16px;
      vertical-align: top;
      letter-spacing: 0.06em;
      line-height: 35px; // レイアウトが崩れるので個別対応
    }
  }
  .live-leave-btn,
  .distribute-btn {
    width: 154px;
    background: #cb2e31;
    color: white;
    &.isTabletAndSp {
      width: 64px;
      div > div {
        margin: 0 auto;
        font-size: 10px;
      }
    }
  }
</style>
