import { ref } from "vue";
import type { Props } from "~/components/Atoms/Dialog/ConfirmDialog.vue";
import ConfirmDialog from "~/components/Atoms/Dialog/ConfirmDialog.vue";

export const useConfirmDialog = () => {
  const confirmDialogRef = ref<InstanceType<typeof ConfirmDialog> | null>(null);

  const openDialog = (options: Props) => {
    if (!confirmDialogRef.value) {
      console.error("YesOrNoDialog component is not mounted yet");
      return;
    }

    confirmDialogRef.value.open(options);
  };

  const setDialogRef = (ref: any) => {
    confirmDialogRef.value = ref;
  };

  return {
    openDialog,
    setDialogRef,
  };
};

const confirmDialogInstance = useConfirmDialog();

export default confirmDialogInstance;
